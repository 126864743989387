import {createStore} from "vuex";
import {setItem, getItem, removeItem} from "@/utils/storage";
import {getIds, getStoreItem} from "@/utils/function";
import {getUserInfo} from "@/api/user";

const store = createStore({
    state: {
        game_id: getIds("game_id") || 0,
        promote_id: getIds("promote_id") || 0,
        user_id: getStoreItem('user_id'),
        token: getStoreItem('token'),
        set_pass: getItem('set_pass'),
        head_img: getItem('headImg'),
        account: getItem('account'),
        isAutoLogin: getStoreItem('is_auto_login') ?? true,
        lastLoginPhone: getItem('lastLoginPhone'),
        historyUserList: getItem('historyUserList') || [],
        smallId: getStoreItem('small_id') || 0,
        masking_status: getItem('masking_status') || 0,
        userInfo: [],
    },
    mutations: {
        setUser(state, data) {
            state.user_id = data.user_id;
            state.token = data.token;
            state.head_img = data.head_img;
            state.account = data.account;
            state.set_pass = data.set_pass;
            setItem('user_id', data.user_id)
            setItem('token', data.token)
            setItem('headImg', data.head_img)
            setItem('account', data.account)
            setItem('set_pass', data.set_pass)
        },
        logout(state) {
            state.user_id = null;
            state.token = null;
            state.account = null;
            state.userInfo = [];
            state.smallId = 0;
            removeItem('user_id')
            removeItem('token')
            removeItem('headImg')
            removeItem('account')
            removeItem('small_id')
        },
        //设置最后一次登录手机号
        setLastLoginPhone(state, phone) {
            state.lastLoginPhone = phone
            setItem('lastLoginPhone', phone)
        },
        //设置历史登录用户
        setHistoryUserList(state, user) {
            state.historyUserList = user
            setItem('historyUserList', user)
        },
        //设置自动登录状态
        setIsAutoLogin(state, data) {
            state.isAutoLogin = data;
            setItem('is_auto_login', data);
        },
        //设置用户信息
        updateUserInfo(state) {
            getUserInfo().then(({data}) => {
                state.userInfo = data
            })
        },
        //设置小号
        setSmallId(state, data) {
            state.smallId = data;
            setItem('small_id', data);
        },
        //更新登录信息
        updateLoginInfo(state) {
            state.user_id = getStoreItem('user_id');
            state.token = getStoreItem('token');
        },
        //更新ios蒙层的状态
        updateSetMaskingStatus(state,data) {
            state.masking_status = data;
            setItem('masking_status', data);
        },
    },
})
export default store;
