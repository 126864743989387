/**
 * 判断是否为安卓游戏包
 * @returns {boolean}
 */
export function is_android_sdk() {
    return Boolean(window.and_youxiyou)
}

/**
 * 判断是否为微端H5包
 * @returns {boolean}
 */
export function is_android_h5() {
    return Boolean(window.youxiyou_h5)
}

/**
 * 判断是否为微信浏览器
 * @returns {boolean}
 */
export function is_wechat() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return userAgent.match(/MicroMessenger/i) == "micromessenger";
}

/**
 * 判断是否为手机
 * @returns {boolean}
 */
export function is_mobile() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const agents = ["android", "iphone", "symbianos", "windows phone", "ipad", "ipod"];
    for (let v = 0; v < agents.length; v++) {
        if (userAgent.indexOf(agents[v]) >= 0) {
            return true;
        }
    }
    return false;
}

/**
 * 判断是否横屏
 * @returns {boolean}
 */
export function isLandscape() {
    // 判断横竖屏
    let width = document.documentElement.clientWidth;
    let height = document.documentElement.clientHeight;
    if (width > height) {
        return true;
    }
    return false
}

/**
 * 判断是否为Safari浏览器
 * @returns {boolean}
 */
export function isSafari() {
    let ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('applewebkit') > -1 && ua.indexOf('mobile') > -1 && ua.indexOf('safari') > -1 &&
        ua.indexOf('linux') === -1 && ua.indexOf('android') === -1 && ua.indexOf('chrome') === -1 &&
        ua.indexOf('ios') === -1 && ua.indexOf('browser') === -1;
}

/**
 * 判断是否使用iOS的桌面快捷方式打开
 * @returns {boolean}
 */
export function isIOSHomeScreen() {
    let userAgent = window.navigator.userAgent.toLowerCase();
    return userAgent.includes('iphone') || userAgent.includes('ipad') && userAgent.includes('safari') // 检测iOS设备且非浏览器环境
}
