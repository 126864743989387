//用户请求模块
import request from '../utils/request'
//用户登录
export const login = (data) => {
    return request({
        method: 'post',
        url: '/user/login',
        data,
    })
}
//注册账号
export const phoneLogin = (data) => {
    return request({
        method: 'post',
        url: '/user/phone-login',
        data,
    })
}
//小号列表
export const smallList = (data) => {
    return request({
        method: 'post',
        url: '/user/small-list',
        data,
    })
}
//添加小号
export const addSmall = (nickname) => {
    return request({
        method: 'post',
        url: '/user/add-small',
        data: {nickname},
    })
}
//编辑小号昵称
export const editSmall = (data) => {
    return request({
        method: 'post',
        url: '/user/edit-small',
        data,
    })
}
//小号登录
export const smallLogin = (small_id) => {
    return request({
        method: 'post',
        url: '/user/small-login',
        data: {small_id},
    })
}
//注册账号
export const smallRegister = (data) => {
    return request({
        method: 'post',
        url: '/user/register',
        data,
    })
}
//忘记密码
export const checkPhoneForgetPass = (data) => {
    return request({
        method: 'post',
        url: '/user/forget-pass',
        data,
    })
}
//修改密码
export function changePass(data,headers={}) {
    return request({
        method: 'post',
        url: '/user/change-pass',
        headers,
        data,
    })
}
//实名认证
export const changeIdcard = (data) => {
    return request({
        method: 'post',
        url: '/user/change-id-card',
        data,
    })
}
//获取个人信息
export const getUserInfo = (data) => {
    return request({
        method: 'post',
        url: '/user/user-info',
        data,
    })
}

//绑定/换绑手机号
export function changeMobile(data) {
    return request({
        method: 'post',
        url: '/user/change-mobile',
        data,
    })
}
//解绑手机号
export function removeMobile(data) {
    return request({
        method: 'post',
        url: '/user/remove-mobile',
        data,
    })
}
//客服获取用户信息
export function userInfoToCustomer(data) {
    return request({
        method: 'post',
        url: '/user/customer-info',
        data,
    })
}
