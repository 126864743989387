import {is_android_sdk} from "@/utils/devices";

export const sendMessage = (data) => {
    window.top.postMessage(data, '*')
}

// 合并参数
export const extend = function (target, options) {
    if (target === undefined || target === null) {
        return options;
    } else {
        if (options) {
            for (const name in options) {
                target[name] = options[name];
            }
        }
        return target;
    }
}

// 登陆失效 拉起登陆
export const callLogin = () => {
    const defaults = {
        action: "login",
    };
    sendMessage(defaults)
}

// 登陆成功
export const loginCallback = (data) => {
    const defaults = {
        action: "loginCallback",
        user_id: '',
        token: '',
    };
    data = extend(defaults, data)
    sendMessage(data)
}

// 支付h回调
export const payCallback = (status, cpOrder) => {
    if (is_android_sdk()) {
        window.and_youxiyou.GetPayResult(status, cpOrder)
    } else {
        const defaults = {
            action: "payCallback",
            status: status,
            cpOrder: cpOrder,
        };
        sendMessage(defaults)
    }
}

export const returnGameCallback = () => {
    if (is_android_sdk()) {
        window.and_youxiyou.FinishWebview()
    } else {
        openUrl('')
    }
}

export const openUrl = (url) => {
    const defaults = {
        action: "openUrl",
        url: url,
    };
    sendMessage(defaults)
}

//隐藏悬浮球
export function setFloat() {
    if (is_android_sdk()) {
        window.and_youxiyou.Derail(2, false)
    } else {
        const defaults = {
            action: "setFloatCallback",
        };
        sendMessage(defaults)
    }
}

//自动登录开关
export function setAutoLogin(status) {
    if (is_android_sdk()) {
        window.and_youxiyou.Derail(1, status)
    } else {
        const defaults = {
            action: "setAutoLoginCallback",
            status: status
        };
        sendMessage(defaults)
    }
}

export function exitPage() {
    if (history.state.back) {
        history.back()
    } else {
        returnGameCallback()
    }
}
// 退出登陆
export const callLogout = () => {
    const defaults = {
        action: "logout",
    };
    sendMessage(defaults)
}
