//ajax工具函数层
import axios from 'axios'
import {Toast} from "vant";
import store from "../store";
import {getTime, makeSign} from "./make";
import {callLogout} from "@/utils/callback";

const request = axios.create({
    baseURL: process.env.VUE_APP_SDK_API_BASE_URL,
    timeout: 5000,
})

// 请求拦截器
request.interceptors.request.use(
    config => {
        let headerData = {
            promote_id: store.state.promote_id,
            version: "h5",
            game_id: store.state.game_id,
            user_id:config.headers?.user_id || 0,
            token:config.headers?.token || '',
        }

        if(store.state.user_id && store.state.token){
            headerData.user_id = store.state.user_id;
            headerData.token = store.state.token;
        }
        config.headers = Object.assign(config.headers, headerData);

        if (!config.data) {
            config.data = {};
        }
        config.data.time_stamp = getTime();
        config.data.sign = makeSign(Object.assign(headerData, config.data));
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
)

request.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data.code === 888){
                callLogout();
            }
            if (response.data.code !== 200) {
                Toast(response.data.message);
                return Promise.reject(response.data);
            }
            return response.data;
        } else {
            Toast('请求失败');
            return Promise.reject('请求失败');
        }
    },
    error => {
        console.log(error);
        Toast('网络请求失败');
        return Promise.reject('网络请求失败');
    }
)

export default request;
