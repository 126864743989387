// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/5/21
// +-------------------------------------------------------------
// |Time: 14:11
// +-------------------------------------------------------------
import {is_android_h5, is_android_sdk} from "./devices";
import {getItem} from "./storage";
import useClipboard from "vue-clipboard3";
import {Toast} from "vant";

/**
 * 数组对象去重
 * @param arr
 * @param uniId
 */
export function uniqueObj(arr, uniId){
    const res = new Map();
    return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1));
}

/**
 * 判断某一属性是否重复
 * @param arrObj
 * @param attr
 * @returns {*[]|*}
 */
export function isUnique(arrObj,key,value) {
    for (let i = 0; i < arrObj.length; i++) {
        if(arrObj[i][key] ==  value){
            return i;
        }
    }
    return -1;
}

export function getIds(name) {
    if (is_android_sdk()){
        return window.and_youxiyou.getData(name) || 0
    }else{
        let query = self != top ? window.name : window.location.href;
        name = name == "game_id" ? 'gid' : 'pid';
        if (query.indexOf(name) > -1){
            let vars = query.substring(query.indexOf(name));
            let paramArr = vars.split("/");
            let varsArr = paramArr[0].split("_");
            return varsArr[1]
        }
    }
    return 0;
}

/**
 *
 * @param timestamp
 * @param formats
 * @returns {string}
 */
export function date(timestamp, formats) {
    if(!timestamp) return;
    // formats格式包括
    // 1. Y-m-d
    // 2. Y-m-d H:i:s
    // 3. Y年m月d日
    // 4. Y年m月d日 H时i分
    formats = formats || 'Y-m-d';

    let zero = function (value) {
        if (value < 10) {
            return '0' + value;
        }
        return value;
    };
    if (timestamp.toString().length === 10) {
        timestamp = parseInt(timestamp + "000")
    }
    let myDate = timestamp ? new Date(timestamp) : new Date();

    let year = myDate.getFullYear();
    let month = zero(myDate.getMonth() + 1);
    let day = zero(myDate.getDate());

    let hour = zero(myDate.getHours());
    let minute = zero(myDate.getMinutes());
    let second = zero(myDate.getSeconds());

    return formats.replace(/Y|m|d|H|i|s/ig, function (matches) {
        return ({
            Y: year,
            m: month,
            d: day,
            H: hour,
            i: minute,
            s: second
        })[matches];
    });
}

//获取store
export function getStoreItem(key){
    if(is_android_sdk()){
        return window.and_youxiyou.getData(key)
    }else if(is_android_h5()){
        try{
            return window.youxiyou_h5.getData(key)
        }catch {
            return getItem(key)
        }
    }else{
        return getItem(key)
    }
}

export function debugMeg(e) {
    if(getItem('is_debugger')){
        console.log(e)
    }
}
//复制操作
export async function asyncCopy(msg){
    const {toClipboard} = useClipboard();
    try {
        await toClipboard(msg);
        Toast('复制成功');
    } catch (e) {
        Toast(msg);
    }
}
// 动态加载外部链接
export function loadOutsideJS(url) {
    return new Promise((resolve) => {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        // IE
        if (script.readyState) {
            script.onreadystatechange = () => {
                if (script.readyState == 'loaded'
                    || script.readyState == 'complete') {
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else {
            // 其他浏览器
            script.onload = function () {
                resolve();
            };
        }
        document.getElementsByTagName('head')[0].appendChild(script);
    })
}
// 返回操作
export const onClickLeft = () => {
    history.back()
}

//自定义类型转换
export function typeFormat(type,custom){
    return custom[type] || type
}
