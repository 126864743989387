import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: "/demo",
        name: "demo",
        component: () => import('../views/game/Demo')
    },
    {
        path: "/game/gid_:game_id",
        name: "game",
        component: () => import("../views/game/Play")
    },
    {
        path: "/game/gid_:game_id/pid_:promote_id",
        name: "GamePlay",
        component: () => import("../views/game/Play")
    },
    // 支付
    {
        path: '/pay/pay-spend',
        name: 'PaySpend',
        component: () => import('../views/pay/Spend')
    },
    // 悬浮球
    {
        path: '/float',
        name: 'Float',
        component: () => import('../views/Float')
    },
    //个人中心
    {
        path: '/personal/person',
        name: 'Person',
        component: () => import('../views/personal/Center/Person'),
    },
    //订单记录
    {
        path: '/personal/spendList',
        name: 'SpendList',
        component: () => import('../views/personal/Center/SpendList'),
    },
    //小号管理
    {
        path: '/personal/subList',
        name: 'PersonalSubList',
        component: () => import('../views/personal/Center/SubList'),
    },
    //修改密码
    {
        path: '/personal/password',
        name: 'PersonalPassword',
        component: () => import('../views/personal/Center/Password'),
    },
    //换绑/绑定手机
    {
        path: '/personal/bind-phone',
        name: 'BindPhone',
        component: () => import('../views/personal/Center/BindPhone'),
    },
    {
        path: '/personal/unbinding-phone',
        name: 'UnbindingPhone',
        component: () => import('../views/personal/Center/UnbindingPhone'),
    },
    //显示绑定手机
    {
        path: '/personal/bound-phone',
        name: 'BoundPhone',
        component: () => import('../views/personal/Center/BoundPhone'),
    },
    //实名认证
    {
        path: '/personal/auth',
        name: 'PersonalAuth',
        component: () => import('../views/personal/Center/Auth'),
    },
    //钱包
    {
        path: '/personal/wallet',
        name: 'Wallet',
        component: () => import('../views/personal/Wallet/Wallet'),
    },
    //礼包
    {
        path: '/personal/gift',
        name: 'GiftList',
        component: () => import('../views/personal/Gift/Gift'),
    },
    {
        path: "/personal/gift-detail/:gift_id",
        name: "GiftDetail",
        component: () => import("../views/personal/Gift/GiftDetail")
    },
    //卡券
    {
        path: '/personal/voucher-card',
        name: 'VoucherCard',
        component: () => import('../views/personal/Card/VoucherCard'),
    },
    //开服表
    {
        path: '/personal/server-list',
        name: 'ServerList',
        component: () => import('../views/personal/ServerList'),
    },
    //活动列表
    {
        path: '/personal/activity',
        name: 'ActivityList',
        component: () => import('../views/personal/Activity/ActivityList'),
    },
    //活动详情
    {
        path: '/personal/activity-detail/:activity_id',
        name: 'ActivityDetail',
        component: () => import('../views/personal/Activity/ActivityDetail'),
    },
    //帮助中心
    {
        path: '/tool/help-center',
        name: 'ToolHelpCenter',
        component: () => import('../views/tool/HelpCenter'),
    },
    //消息列表
    {
        path: '/tool/notice-list',
        name: 'ToolNoticeList',
        component: () => import('../views/tool/NoticeList'),
    },
    //消息详情
    {
        path: '/tool/notice-detail/:notice_id',
        name: 'ToolNoticeDetail',
        component: () => import('../views/tool/NoticeDetail'),
    },
    // 错误路由
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import('../views/404.vue')
    },
    //申请返利
    {
        path: '/personal/activity-rebate/:activity_id/:game_name/:ids?',
        name: 'ActivityRebateOperate',
        component: () => import('../views/personal/Activity/RebateOperate'),
    },
    {
        path: '/personal/activity-rebate-detail/:ids',
        name: 'ActivityRebateDetail',
        component: () => import('../views/personal/Activity/RebateDetail'),
    },
    {
        path: '/personal/activity-rebate-list/',
        name: 'ActivityRebateList',
        component: () => import('../views/personal/Activity/RebateList'),
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,

});

export default router
